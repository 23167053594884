var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-box",
      attrs: { title: "请输入自定义物品价值", width: 500 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c("div", { staticClass: "color-red" }, [
            _vm._v("请输入物品真实价值价值（必须要大于1000）！！！")
          ]),
          _c("a-input", {
            staticClass: "w-300 mt-10",
            attrs: { allowClear: "", type: "number", placeholder: "产品金额" },
            model: {
              value: _vm.productPrice,
              callback: function($$v) {
                _vm.productPrice = $$v
              },
              expression: "productPrice"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }