var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "a-radio-group",
        {
          attrs: {
            "default-value": 1,
            disabled: _vm.disabledRadioGroupStatus,
            "button-style": "solid",
            size: "large"
          },
          on: { change: _vm.handleChangeSellerOrBuyer }
        },
        [
          _c("a-radio-button", { attrs: { value: 1 } }, [
            _vm._v("买家测-评级详情")
          ]),
          _c("a-radio-button", { attrs: { value: 2 } }, [
            _vm._v("卖家测-评级详情")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }