<template>
  <div class="selected-save-price-about">
    <div class="save-price-title">选择保价相关</div>
<!--  预付费用描述  -->
    <div class="save-price-text-desc">保价费随快递到付结算，声明价值1000元以上时保价费为千分之五，四舍五入取整数费率仅供参考</div>
<!--  预估费用  -->
    <div class="save-price-text-price">
      <div>
        保价费用：
        <template v-if="currentSavePriceValue"><span class="color-green font-weight-bold">￥{{ totalLogisticsPrice }}</span>（顺丰到付）</template>
        <template v-else><span class="color-red font-weight-bold">请先选择寄回地址，是否保价</span></template>
      </div>
    </div>
<!--  选择保价  -->
    <div class="save-price-select-list">
      <div
          class="save-price-item"
          :class="currentSavePriceValue === item.value ? 'active-save-price-item' : ''"
          v-for="(item, index) in selectedSavePriceList"
          :key="index"
          @click="handleSelectedItem(item)"
      >
        <div
            class="price-item-title"
            :class="currentSavePriceValue === item.value ? 'color-dq-blue' : ''"
            v-html="item.title"
        ></div>
        <div class="price-item-value">
          <div
              v-if="item.value === 'custom' && !item.ratePrice"
              class="mt-5 font-weight-bold color-blue cur-pot"
          >自定义物品价值</div>
          <div
              v-if="item.ratePrice"
              class="mt-5 font-weight-bold color-black"
              :class="currentSavePriceValue === item.value ? 'color-dq-blue' : ''"
          >
            保价费用：<span class="font-weight-bold color-red font-size-16">￥{{ item.ratePrice }}</span>
          </div>
        </div>
        <div
            v-if="item.value === 'custom' && item.price"
            class="mt-5 font-weight-bold"
            :class="currentSavePriceValue === item.value ? 'color-dq-blue' : ''"
        >
          自定义填写的物品价值：<span class="color-red">￥{{ item.price }}
        </span>
        </div>
      </div>
    </div>
<!--  输入  -->
    <CustomInputSavePricePopup ref="customInputSavePricePopupEl" @inputSuccess="handleInputSuccess($event)" />
  </div>
</template>
<script>
import {HAS_NOT_SAVE_PRICE_LIT} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_data"
import CustomInputSavePricePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/_compoents/ShowConfirmBtnNeedAndSendProductPopup/_components/CustomInputSavePricePopup/index.vue"

export default {
  components: {
    CustomInputSavePricePopup
  },
  props: ['info', 'params', 'forecastPostage'],
  emits: ['changeSavePrice'],
  data() {
    return {
      selectedSavePriceList: JSON.parse(JSON.stringify(HAS_NOT_SAVE_PRICE_LIT)),
      currentSavePriceValue: undefined,
      logisticsPrice: 0,
      totalLogisticsPrice: 0,
      savePriceParams: {
        savePrice: 0, // 保价的物品总金额
        saveRatePrice: 0, // 保价的金额
        customSavePrice: 0, // 自定义物品总金额
        customSaveRatePrice: 0, // 自定义保价金额
      }
    }
  },
  watch: {
    forecastPostage(newValue) {
      this.logisticsPrice = newValue * 1
      if (this.currentSavePriceValue) {
        if (this.currentSavePriceValue === 'custom') {
          this.totalLogisticsPrice = newValue * 1 + this.savePriceParams.customSaveRatePrice
        } else {
          this.totalLogisticsPrice = newValue * 1 + this.savePriceParams.saveRatePrice
        }
      } else {
        this.totalLogisticsPrice = newValue * 1
      }
    },
  },
  methods: {
    handleSelectedItem(item) {
      if (!this.params.addressId) return this.$message.warning('请先选择寄回地址')
      if (item.value === 'custom') {
        this.$refs.customInputSavePricePopupEl.show()
      } else {
        this.currentSavePriceValue = item.value
        this.totalLogisticsPrice = this.logisticsPrice + item.ratePrice
        this.savePriceParams = {
          savePrice: item.price, // 保价的物品总金额
          saveRatePrice: item.ratePrice, // 保价的金额
          customSavePrice: 0, // 自定义物品总金额
          customSaveRatePrice: 0, // 自定义保价金额
        }
        this.selectedSavePriceList[3].price = 0
        this.selectedSavePriceList[3].ratePrice = 0
        this.params.insuredPrice = item.price * 1
      }
    },
    /** 自定义输入内容 */
    handleInputSuccess(data) {
      this.totalLogisticsPrice = this.logisticsPrice + data.customSaveRatePrice
      this.savePriceParams = {
        savePrice: 0, // 保价的物品总金额
        saveRatePrice: 0, // 保价的金额
        customSavePrice: data.customSavePrice, // 自定义物品总金额
        customSaveRatePrice: data.customSaveRatePrice, // 自定义保价金额
      }
      this.params.insuredPrice = data.customSavePrice * 1
      this.selectedSavePriceList[3].price = data.customSavePrice
      this.selectedSavePriceList[3].ratePrice = data.customSaveRatePrice
      this.currentSavePriceValue = 'custom'
    },
    handleCancelSelectSavePrice() {
      this.selectedSavePriceList = JSON.parse(JSON.stringify(HAS_NOT_SAVE_PRICE_LIT))
      this.currentSavePriceValue = undefined
      this.logisticsPrice = 0
      this.totalLogisticsPrice = 0
      this.savePriceParams = {
        savePrice: 0, // 保价的物品总金额
        saveRatePrice: 0, // 保价的金额
        customSavePrice: 0, // 自定义物品总金额
        customSaveRatePrice: 0, // 自定义保价金额
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.save-price-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  width: 300px;
  height: 92px;
  border-radius: 4px;
  border: 1px solid #000000;
  cursor: pointer;
}
.save-price-select-list {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.save-price-text-desc {
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #9B9B9B;
}
.save-price-text-price {
  margin-top: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #32788A;
  span {
    text-decoration: underline;
  }
}
.selected-save-price-about {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}
.save-price-title {
  font-weight: bold;
  font-size: 16px;
  color: #2C2C2C;
}
.active-save-price-item {
  background: #EAF1F2;
  border: 2px solid #32788A;
}
</style>