<template>
  <div class="">
    <a-radio-group
        :default-value="1"
        :disabled="disabledRadioGroupStatus"
        button-style="solid" size="large"
        @change="handleChangeSellerOrBuyer"
    >
      <a-radio-button :value="1">买家测-评级详情</a-radio-button>
      <a-radio-button :value="2">卖家测-评级详情</a-radio-button>
    </a-radio-group>
  </div>
</template>
<script>
export default {
  emits: ['changeSellerOrBuyer'],
  props: ['params', 'disabledRadioGroupStatus'],
  data() {
    return {}
  },
  methods: {
    handleChangeSellerOrBuyer(e) {
      this.$emit('changeSellerOrBuyer', e.target.value)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>