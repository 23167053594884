var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-box",
      attrs: { title: "需要并保价", width: 700 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "flex-end-center" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-20",
                      attrs: { type: "default" },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: { title: "确定执行当前操作吗？" },
                      on: { confirm: _vm.handleConfirm }
                    },
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v("确定")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-content" },
        [
          _vm.detailInfo
            ? _c("SelectedAddressListAbout", {
                attrs: {
                  "address-list": _vm.detailInfo.addressList,
                  params: _vm.params
                },
                on: {
                  changeAddress: function($event) {
                    return _vm.handleChangeAddress($event)
                  }
                }
              })
            : _vm._e(),
          _vm.detailInfo
            ? _c("SelectedSavePriceAbout", {
                ref: "selectedSavePriceAboutEl",
                attrs: {
                  forecastPostage: _vm.forecastPostage,
                  params: _vm.params,
                  info: _vm.detailInfo
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }