<template>
  <a-modal class="modal-box" v-model="showPopup" title="请输入自定义物品价值" :width="500" @cancel="handleCancel" @ok="handleConfirm">
    <div class="content-box">
      <div class="color-red">请输入物品真实价值价值（必须要大于1000）！！！</div>
      <a-input allowClear v-model="productPrice" type="number" class="w-300 mt-10" placeholder='产品金额'></a-input>
    </div>
  </a-modal>
</template>
<script>
export default {
  emits: ['inputSuccess'],
  data() {
    return {
      showPopup: false,
      productPrice: undefined,
    }
  },
  methods: {
    show() {
      this.showPopup = true
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.productPrice || this.productPrice <= 1000) return this.$message.warning('请输入真实的大于1000的产品金额，如果产品金额小于1000，请选择其他保价')
      const params = {
        customSavePrice: this.productPrice * 1,
        customSaveRatePrice: Math.round(this.productPrice * 0.005)
      }
      this.$emit('inputSuccess', params)
      this.handleCancel()
    },
    handleCancel() {
      this.showPopup = false
      this.productPrice = undefined
    },
  }
}
</script>
<style scoped lang="scss">
.modal-content {
  padding: 10px;
  background: #F4F6F6;
}
</style>