
/** 订单列表 */
export const orderTableColumns = [
    { title: "订单号", align: "center", scopedSlots: {customRender: 'itemOrderNoSlot'}, width: 230 },
    { title: "产品名称", align: "center", scopedSlots: {customRender: 'itemProductNameSlot' }, width: 150 },
    { title: "产品快照", align: "center", scopedSlots: {customRender: 'itemProductImgListSlot' }, width: 220 },
    { title: "订单金额", align: "center", scopedSlots: {customRender: 'itemOrderPriceAndPayTypeSlot' }, width: 150 },
    { title: "订单状态", align: "center", scopedSlots: {customRender: 'itemOrderStatusSlot' }, width: 300 },
    { title: "买/卖家信息", align: "center", scopedSlots: {customRender: 'itemBuyerInfoOrSellerInfoSlot' }, width: 350 },
    { title: "下单时间", align: "center", dataIndex: 'createTime', width: 120 },
    { title: "操作相关", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 200 }
]

/** 评级类型 */
export const serviceTypeList = [
    { name: '放心购-选择评级', value: 1 },
    { name: '放心购-放弃评级', value: 2 },
    { name: '没有放心购-选择评级', value: 3 },
]

/** 发起退货退款申请原因 */
export const createApplyReturnPriceOrReturnProductReasonList = [
    {name: '买家无理由[买家责任]-[扣买家]', value: 'buyernoreason'},
    {name: '商品瑕疵[卖家责任]-[扣卖家]', value: 'productflaw'},
    {name: '图片与实物不符[卖家责任]-[扣卖家]', value: 'sellernoreason'},
    {name: '假货[卖家责任]-[扣卖家]', value: 'sham'},
]

/** 价格排序搜索 */
export const priceSortList = [
    { name: '价格升序', value: 1 },
    { name: '价格降序', value: -1 },
]

/** 原因对应的数据 */
export const applyReturnPriceOrReturnProductReasonMapText = {
    returnGoodsing: { // 快递已经原路退回中
        logisticsText: '买家承担卖家发货运费',
        bindPriceText: '是否扣除买家保证金',
    },
    buyernoreason: { // 买家无理由
        logisticsText: '买家承担卖家发货运费',
        bindPriceText: '是否扣除买家保证金',
    },
    sellernoreason: { // 卖家无理由（超时未发货）
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    versionNotSame: { // 版别描述不符
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    physicaldiscrepancy: { // 实物与图文不符
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    logisticsIssues: { // 物流问题
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    goodsNotTake: { // 快递还在快递点没有取
        logisticsText: '买家承担卖家发货运费',
        bindPriceText: '是否扣除买家保证金',
    },
    consensus: { // 协商一致
        logisticsText: '协商一致是否补偿/承担运费',
        bindPriceText: '协商一致是否扣除保证金',
    },
    sham: { // 假货
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    productflaw: { // 有未展示的品相缺陷
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    productXiaci: { // 有未说明的钱币瑕疵
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    seriousAberration: { // 严重色差
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    pcgsProductflaw: { // PCGS有未展示的品相缺陷
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    },
    emptyPackage: { // 空包裹
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
    }
}

/** 创建送评申请理由 */
export const applyRateServiceReasonTypeList = [
    { name: '严重色差', value: 'seriousAberration' },
    { name: '我认为是假货', value: 'sham' },
    { name: '版别描述不符', value: 'versionNotSame' },
    { name: '有未说明的钱币瑕疵', value: 'productXiaci' },
]

/** 选择列表---有不保价的选择列表 */
export const HAS_NOT_SAVE_PRICE_LIT = [
    { title: '<span style="color: #2C2C2C; font-weight: bold;">不保价</span>，丢失损毁三倍运费赔偿', price: 0, ratePrice: 0, value: 'not' },
    { title: '<span style="color: #2C2C2C; font-weight: bold;">保价</span>，价值500元（含）以下', price: 500, ratePrice: 1, value: 'one' },
    { title: '<span style="color: #2C2C2C; font-weight: bold;">保价</span>，价值501-1000元（含）', price: 1000, ratePrice: 2, value: 'two' },
    { title: '<span style="color: #2C2C2C; font-weight: bold;">保价</span>，自定义保价物品价值', price: 0, ratePrice: 0, value: 'custom' },
]