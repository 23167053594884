<template>
  <div class="rate-bottom-button-list-about">
    <a-button
        class="mr-20"
        v-for="btn in info.buttonList"
        :key="btn.btnType"
        :type="btn.btnStyleType"
        @click="handleConfirmAction(btn)"
    >{{ btn.btnText }}</a-button>
<!--  提示弹窗  -->
    <ShowConfirmBtnTipMessagePopup ref="showConfirmBtnTipMessagePopupEl" @success="handleSuccess"/>
<!--  展示确定数据  -->
    <ShowConfirmBtnNeedAndSendProductPopup ref="showConfirmBtnNeedAndSendProductPopupEl" @success="handleSuccess" />
  </div>
</template>
<script>
import ShowConfirmBtnTipMessagePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/_compoents/ShowConfirmBtnTipMessagePopup/index.vue"
import ShowConfirmBtnNeedAndSendProductPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/_compoents/ShowConfirmBtnNeedAndSendProductPopup/index.vue"

export default {
  components: { ShowConfirmBtnTipMessagePopup, ShowConfirmBtnNeedAndSendProductPopup },
  props: ['info'],
  emits: ['success'],
  data() {
    return {}
  },
  methods: {
    /** 确定操作 */
    handleConfirmAction(btn) {
      if (['needNotSendProduct', 'notNeed'].includes(btn.btnType)) {
        this.$refs.showConfirmBtnTipMessagePopupEl.show(btn, this.info)
      } else if (btn.btnType === 'needAndSendProduct') {
        this.$refs.showConfirmBtnNeedAndSendProductPopupEl.show(this.info)
      }
    },
    /** 确定 */
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style scoped lang="scss">
.rate-bottom-button-list-about {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}
</style>