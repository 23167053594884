var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-box",
      attrs: { title: "注意", width: 400 },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "flex-end-center" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-20",
                      attrs: { type: "default" },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: { title: "确定执行当前操作吗？" },
                      on: { confirm: _vm.handleConfirm }
                    },
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v("确定")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "color-red" },
        [
          _c("a-icon", {
            staticClass: "mr-10",
            attrs: { type: "info-circle" }
          }),
          _c("span", [_vm._v(_vm._s(_vm.btnInfo.tipMessage))]),
          _c("div", { staticClass: "mt-10 color-red" }, [
            _vm._v("谨慎操作！！！")
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }