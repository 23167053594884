var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("订单原价/中拍价："),
        _c("span", { staticClass: "font-weight-bold color-red" }, [
          _vm._v("￥" + _vm._s(_vm.orderProductInfoVo.orderPrice))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("买家手续费："),
        _c("span", { staticClass: "font-weight-bold color-red" }, [
          _vm._v("￥" + _vm._s(_vm.orderProductInfoVo.buyerServiceMoney))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("卖家手续费："),
        _c("span", { staticClass: "font-weight-bold color-red" }, [
          _vm._v("￥" + _vm._s(_vm.orderProductInfoVo.sellerServiceMoney))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("运费："),
        _c("span", { staticClass: "font-weight-bold color-red" }, [
          _vm._v("￥" + _vm._s(_vm.orderProductInfoVo.logisticsFee))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("买家实际支付金额："),
        _c("span", { staticClass: "font-weight-bold color-red" }, [
          _vm._v(
            "￥" + _vm._s(_vm.orderProductInfoVo.orderTotalPrice || "包邮")
          )
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("支付方式："),
        _c(
          "span",
          {
            staticClass: "font-weight-bold",
            class: _vm.payTypeListClassType[_vm.orderProductInfoVo.payType]
          },
          [
            _vm._v(
              _vm._s(
                _vm.computedPayTypeListMapText(_vm.orderProductInfoVo.payType)
              )
            )
          ]
        )
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("支付时间："),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.orderProductInfoVo.payTime))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }