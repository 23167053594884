var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rate-result-about" }, [
    _c("div", { staticClass: "title-box" }, [
      _c("div", { staticClass: "title-text" }, [
        _vm._v(_vm._s(_vm.rateResultInfo.title))
      ]),
      _vm.rateResultInfo.huaXiaUrl
        ? _c(
            "div",
            {
              staticClass: "hua-xia-title cur-pot",
              on: { click: _vm.handleGoToHuaXiaSearchCode }
            },
            [_vm._v("查看华夏官网 >")]
          )
        : _c("div")
    ]),
    _c(
      "div",
      { staticClass: "rate-result-list" },
      _vm._l(_vm.rateResultInfo.resultLineList, function(item, index) {
        return _c("div", { key: index, staticClass: "rate-result-item" }, [
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(item.title))
          ]),
          _c("div", { staticClass: "item-value" }, [_vm._v(_vm._s(item.value))])
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "rate-result-img-list" },
      _vm._l(_vm.rateResultInfo.resultImgList, function(img, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "img-item",
            on: {
              click: function($event) {
                return _vm.handleShowBigImg(
                  _vm.rateResultInfo.resultImgList,
                  index
                )
              }
            }
          },
          [_c("img", { attrs: { src: img, alt: "" } })]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }