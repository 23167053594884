<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   订单号相关   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <div class="font-weight-bold" :class="orderTypeClassType[row.orderKind]">{{ computedOrderTypeListMapText(row.orderKind) }}</div>
        <div class="mt-5 font-weight-bold">【<span class="color-blue">{{ row.orderNo }}</span>】</div>
      </div>
<!--   产品名称   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div
            v-if="row.productReviewStatus"
            :class="orderProductLevelClassType[row.productReviewStatus]"
        >【{{ computedOrderProductLevelMapText(row.productReviewStatus) }}】</div>
        <div class="mt-10 font-weight-bold color-black">{{ row.productName }}</div>
      </div>
<!--   产品快照   -->
      <div slot="itemProductImgListSlot" slot-scope="row" class="flex-start-center flex-wrap">
        <div v-for="(item, index) in row.snapShootPicList" :key="index" v-lazy-container="{ selector: 'img' }">
          <img
              @click="handleShowBigImg(row.snapShootPicList, index)"
              class="w-100 h-100"
              :data-src="item"
          />
        </div>
      </div>
<!--  订单金额    -->
      <div slot="itemOrderPriceAndPayTypeSlot" slot-scope="row">
        <div class="font-weight-bold color-red">￥{{ row.orderPrice }}</div>
        <div class="mt-5 font-weight-bold">【<span :class="payTypeListClassType[row.payType]">{{ computedPayTypeListMapText(row.payType) }}</span>】</div>
      </div>
<!--  订单状态    -->
      <div slot="itemOrderStatusSlot" slot-scope="row">
        <div class="font-weight-bold">订单状态【<span :class="orderStatusTypeListClassType[row.status]">{{ computedOrderStatusTypeListMapText(row.status) || '未知' }}</span>】</div>
        <div class="mt-5 font-weight-bold">评级类型【<span :class="rateServiceTypeStatusClassType[row.serviceType]">{{ computedRateServiceTypeStatusListMapText(row.serviceType) || '其他' }}</span>】</div>
        <div class="mt-5 font-weight-bold">退款状态【<span :class="orderReturnPriceStatusClassType[row.refundStatus]">{{ computedOrderReturnPriceStatusMapText(row.refundStatus) || '暂无' }}</span>】</div>
      </div>
<!--  买家，卖家信息    -->
      <div slot="itemBuyerInfoOrSellerInfoSlot" slot-scope="row">
        <div class="font-weight-bold">买家信息【<span class="color-green">{{ row.buyerUserName + '---' + row.buyerUserId }}</span>】</div>
        <div class="mt-5 font-weight-bold">卖家信息【<span class="color-blue">{{ row.sellerUserName + '---' + row.sellerUserId }}</span>】</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button type="primary" @click="handleShowOrderDetail(row)">订单详情</a-button>
      </div>
    </a-table>
<!--  详情弹窗  -->
    <OrderDetailPopup ref="orderDetailPopupEl" @success="handleSuccess" />
  </div>
</template>
<script>
import {
  orderTableColumns
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_data"
import {
  orderProductLevelClassType,
  orderProductLevelMapText, orderReturnPriceStatusClassType,
  orderReturnPriceStatusMapText,
  orderStatusTypeListClassType,
  orderStatusTypeListMapText,
  orderTypeClassType,
  orderTypeListMapText,
  rateServiceTypeStatusClassType,
  rateServiceTypeStatusListMapText,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import {payTypeListClassType, payTypeListMapType} from "@/views/cmsPage/dqPlatform/_data"
import OrderDetailPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/index.vue"

export default {
  components: {OrderDetailPopup},
  props: ['pagination', 'list'],
  data() {
    return {
      payTypeListClassType, // 支付方式
      orderProductLevelClassType, // 等级样式
      orderTypeClassType, // 订单类型样式类
      orderStatusTypeListClassType, // 订单状态
      rateServiceTypeStatusClassType, // 评级状态
      orderReturnPriceStatusClassType, // 退款状态
      tableList: orderTableColumns,
    }
  },
  computed: {
    computedOrderTypeListMapText() { // 订单类型
      return (value) => orderTypeListMapText(value)
    },
    computedPayTypeListMapText() { // 支付类型
      return (value) => payTypeListMapType(value)
    },
    computedOrderStatusTypeListMapText() { // 订单状态
      return (value) => orderStatusTypeListMapText(value)
    },
    computedRateServiceTypeStatusListMapText() { // 评级状态
      return (value) => rateServiceTypeStatusListMapText(value)
    },
    computedOrderReturnPriceStatusMapText() { // 退款状态
      return (value) => orderReturnPriceStatusMapText(value)
    },
    computedOrderProductLevelMapText() { // 等级
      return (value) => orderProductLevelMapText(value)
    },
  },
  methods: {
    /** 查看订单详情页 */
    handleShowOrderDetail(row) {
      this.$refs.orderDetailPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 查看大图 */
    handleShowBigImg(urls, index) {
      const tempUrls = urls.map(el => ({img_url: el}))
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: tempUrls,
          current: index,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>