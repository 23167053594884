import {objArrMapObj} from "@/untils/otherEvent"

/** 订单列表 */
export const orderTableColumns = [
    { title: "订单号", align: "center", scopedSlots: {customRender: 'itemOrderNoSlot'}, width: 230 },
    { title: "产品名称", align: "center", scopedSlots: {customRender: 'itemProductNameSlot' }, width: 150 },
    { title: "产品快照", align: "center", scopedSlots: {customRender: 'itemProductImgListSlot' }, width: 440 },
    { title: "订单金额", align: "center", scopedSlots: {customRender: 'itemOrderPriceAndPayTypeSlot' }, width: 170 },
    { title: "订单状态", align: "center", scopedSlots: {customRender: 'itemOrderStatusSlot' }, width: 300 },
    { title: "买/卖家信息", align: "center", scopedSlots: {customRender: 'itemBuyerInfoOrSellerInfoSlot' }, width: 350 },
    { title: "下单时间", align: "center", dataIndex: 'createTime', width: 180 },
    { title: "操作相关", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 200 }
]


/** 发起退货退款申请原因 */
export const createApplyReturnPriceOrReturnProductReasonList = [
    {name: '买家无理由[买家责任]-[扣买家]', value: 'buyernoreason'},
    {name: '商品瑕疵[卖家责任]-[扣卖家]', value: 'productflaw'},
    {name: '图片与实物不符[卖家责任]-[扣卖家]', value: 'sellernoreason'},
    {name: '假货[卖家责任]-[扣卖家]', value: 'sham'},
]

/** 原因对应的数据 */
export const applyReturnPriceOrReturnProductReasonMapText = {
    buyernoreason: {
        logisticsText: '买家承担卖家发货运费',
        bindPriceText: '是否扣除买家保证金',
        otherPriceText: '买家承担卖家额外费用',
    },
    productflaw: {
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
        otherPriceText: '卖家承担买家送评额外费用',
    },
    sham: {
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
        otherPriceText: '卖家承担买家送评额外费用',
    },
    sellernoreason: {
        logisticsText: '卖家承担买家退货运费',
        bindPriceText: '是否扣除卖家保证金',
        otherPriceText: '卖家承担买家额外费用',
    }
}