var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selected-save-price-about" },
    [
      _c("div", { staticClass: "save-price-title" }, [_vm._v("选择保价相关")]),
      _c("div", { staticClass: "save-price-text-desc" }, [
        _vm._v(
          "保价费随快递到付结算，声明价值1000元以上时保价费为千分之五，四舍五入取整数费率仅供参考"
        )
      ]),
      _c("div", { staticClass: "save-price-text-price" }, [
        _c(
          "div",
          [
            _vm._v(" 保价费用： "),
            _vm.currentSavePriceValue
              ? [
                  _c("span", { staticClass: "color-green font-weight-bold" }, [
                    _vm._v("￥" + _vm._s(_vm.totalLogisticsPrice))
                  ]),
                  _vm._v("（顺丰到付）")
                ]
              : [
                  _c("span", { staticClass: "color-red font-weight-bold" }, [
                    _vm._v("请先选择寄回地址，是否保价")
                  ])
                ]
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "save-price-select-list" },
        _vm._l(_vm.selectedSavePriceList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "save-price-item",
              class:
                _vm.currentSavePriceValue === item.value
                  ? "active-save-price-item"
                  : "",
              on: {
                click: function($event) {
                  return _vm.handleSelectedItem(item)
                }
              }
            },
            [
              _c("div", {
                staticClass: "price-item-title",
                class:
                  _vm.currentSavePriceValue === item.value
                    ? "color-dq-blue"
                    : "",
                domProps: { innerHTML: _vm._s(item.title) }
              }),
              _c("div", { staticClass: "price-item-value" }, [
                item.value === "custom" && !item.ratePrice
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-5 font-weight-bold color-blue cur-pot"
                      },
                      [_vm._v("自定义物品价值")]
                    )
                  : _vm._e(),
                item.ratePrice
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-5 font-weight-bold color-black",
                        class:
                          _vm.currentSavePriceValue === item.value
                            ? "color-dq-blue"
                            : ""
                      },
                      [
                        _vm._v(" 保价费用："),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-bold color-red font-size-16"
                          },
                          [_vm._v("￥" + _vm._s(item.ratePrice))]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              item.value === "custom" && item.price
                ? _c(
                    "div",
                    {
                      staticClass: "mt-5 font-weight-bold",
                      class:
                        _vm.currentSavePriceValue === item.value
                          ? "color-dq-blue"
                          : ""
                    },
                    [
                      _vm._v(" 自定义填写的物品价值："),
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v("￥" + _vm._s(item.price) + " ")
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _c("CustomInputSavePricePopup", {
        ref: "customInputSavePricePopupEl",
        on: {
          inputSuccess: function($event) {
            return _vm.handleInputSuccess($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }