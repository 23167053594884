<template>
  <div>
    <div class="flex-start-center flex-wrap mt-20">
      <div class="cell-box">订单编号：<span class="font-weight-bold color-dq-blue">{{ orderProductInfoVo.orderNo }}</span></div>
      <div class="ml-40 cell-box">买家：<span class="font-weight-bold color-dq-blue">{{ orderProductInfoVo.buyerUserName }}</span></div>
      <div class="ml-40 cell-box">卖家：<span class="font-weight-bold color-dq-blue">{{ orderProductInfoVo.sellerUserName }}</span></div>
      <div class="ml-40 cell-box">下单/截拍时间：<span>{{ orderProductInfoVo.createTime }}</span></div>
      <div class="ml-40 cell-box flex-start-center">
        订单正向主状态：
        <span class="font-weight-bold color-black" :class="orderStatusTypeListClassType[orderProductInfoVo.status]" >{{ orderProductInfoVo.statusName }}</span>
        <span class="font-weight-bold color-red" v-if="orderProductInfoVo.offlineStatus">（线下交易）</span>
        <span
            class="flex-start-center"
            v-if="orderProductInfoVo.orderRemainTimeLong && showCountTimeStatus"
        >
          （<BaseCountdownCom ref="baseCountdownComEl" :remaining-time="orderProductInfoVo.orderRemainTimeLong"/>）
        </span>
        <a-button
            type="primary"
            size="small"
            v-if="orderProductInfoVo.status === 0 && qrCodeList && qrCodeList.length"
            @click="handleShowCollectionQRCodePopup(qrCodeList)"
        >查看大额订单拆分收款码</a-button>
      </div>
      <div class="ml-40 cell-box flex-start-center" v-if="orderProductInfoVo.refundStatus">
        订单逆向子状态：
        <span
            class="font-weight-bold color-black"
            :class="returnReviewStatusListClassType[orderReturnVo.returnsReviewStatus]"
        >{{ computedReturnReviewStatusListMapText(orderReturnVo.returnsReviewStatus) }}</span>
        <span
            class="flex-start-center"
            v-if="orderProductInfoVo.orderReturnRemainTimeLong && showCountTimeStatus"
        >
          （<BaseCountdownCom ref="baseCountdownComByReturnEl" :remaining-time="orderProductInfoVo.orderReturnRemainTimeLong"/>）
        </span>
        <a-button size="small" @click="handleGoToReturnOrderList(orderProductInfoVo.orderNo)">查看退款单</a-button>
      </div>
    </div>
    <div class="flex-start-center flex-wrap mt-20" v-if="productSnapshot">
      <div class="cell-box mr-40">商品名称：<span class="font-weight-bold">{{ productSnapshot.productName }}</span></div>
      <div class="cell-box mr-40">商品介绍：<span class="font-weight-bold">{{ productSnapshot.content || '无' }}</span></div>
      <div class="cell-box mr-40">商品瑕疵：<span class="font-weight-bold color-red">{{ computedProductRemark(productSnapshot.productRemark) }}</span></div>
      <div class="cell-box mr-40">
        评级公司：
        <span class="font-weight-bold color-dq-blue">{{ productSnapshot.ratingCompanyName }}</span>
        <span class="font-weight-bold color-dq-blue" v-if="productSnapshot.productCode">【{{ productSnapshot.productCode }}】</span>
      </div>
      <div class="cell-box mr-40">心理价：<span class="font-weight-bold color-red">￥{{ productSnapshot.finalPrice }}</span></div>
      <div class="cell-box mr-40">尺寸重量：<span class="font-weight-bold color-dq-blue">{{ productSnapshot.productSize }}</span></div>
    </div>
    <div class="flex-start-center flex-wrap mt-20">
      <div class="cell-box flex-start-center flex-wrap">
        商品图片：
        <div class="w-50 h-50 flex-center-center" v-for="(img, index) in productSnapshot.files" :key="index">
          <img class="w-100-w h-100-h" :src="img" alt="" @click="handleShowBigImg(productSnapshot.files, index)">
        </div>
      </div>
    </div>
    <div class="flex-start-center flex-wrap mt-20">
      卖家备注信息：<span class="font-weight-bold color-red">{{ productSnapshot.notes || '无' }}</span>
    </div>
<!--  大额  -->
    <CollectionQRCodePopup ref="collectionQRCodePopupEl"/>
  </div>
</template>
<script>
import BaseCountdownCom from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/BaseCountdownCom/index.vue";
import _orderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_orderDetailMixin"
import {goToOtherPage} from "@/untils/otherEvent"
import {
  orderReturnPriceStatusClassType,
  orderStatusTypeListClassType, returnReviewStatusListClassType, returnReviewStatusListMapText
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import CollectionQRCodePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderSplitPayPopup/_components/CollectionQRCodePopup/index.vue"

export default {
  components: {BaseCountdownCom, CollectionQRCodePopup},
  mixins: [_orderDetailMixin],
  props: [
      'productSnapshot', 'orderProductInfoVo', 'orderReturnVo', 'showCountTimeStatus',
      'qrCodeList'
  ],
  data() {
    return {
      orderStatusTypeListClassType,  // 订单状态
      orderReturnPriceStatusClassType, // 订单逆向子状态
      returnReviewStatusListClassType, // 订单逆向子状态
    }
  },
  computed: {
    computedReturnReviewStatusListMapText() {
      return (value) => returnReviewStatusListMapText(value)
    },
    computedProductRemark() {
      return (remark) => {
        if (remark) {
          const tempJson = JSON.parse(remark)
          return `${tempJson.coinStateRemark || '无'}/${tempJson.customRemark || '无'}`
        } else {
          return '无'
        }
      }
    },
  },
  methods: {
    /** 显示大额拆分订单收款码 */
    handleShowCollectionQRCodePopup(list) {
      this.$refs.collectionQRCodePopupEl.show(list)
    },
    /** 查看逆向订单 */
    handleGoToReturnOrderList(orderNo) {
      goToOtherPage(`/cms/dqplatform/neworderreturnmannage?orderNo=${orderNo}`)
    },
    handleClearTimer() {
      if (this.$refs.baseCountdownComEl) {
        this.$refs.baseCountdownComEl.clearTimer()
      }
    },
    handleClearTimerByReturn() {
      if (this.$refs.baseCountdownComByReturnEl) {
        this.$refs.baseCountdownComByReturnEl.clearTimer()
      }
    }
  }
}
</script>