<template>
  <div>
    <a-drawer title="订单详情" placement="right" width="1400" :visible="shopPopup" @close="handleClosePopup">
<!--   基本信息   -->
      <BaseItemCom :title-text="`基本信息（${computedOrderTypeListMapText(orderType)}）`">
        <template #itemInfo>
          <OrderBaseInfo
              ref="orderBaseInfoEl"
              :product-snapshot="productSnapshot"
              :order-product-info-vo="orderProductInfoVo"
          />
        </template>
      </BaseItemCom>
<!--   评级信息   -->
      <BaseItemCom title-text="评级信息" v-if="orderProductInfoVo && ratingInfoVo.ratingPayStatus && ![0, 2, 4].includes(orderProductInfoVo.serviceType)">
        <template #itemInfo>
          <RateAboutInfo
              :orderId="orderId"
              :ratingInfoVo="ratingInfoVo"
              :orderProductInfoVo="orderProductInfoVo"
              @success="handleSuccess"
          />
        </template>
      </BaseItemCom>
<!--   支付信息   -->
      <BaseItemCom title-text="支付信息">
        <template #itemInfo>
          <OrderPayInfo :order-product-info-vo="orderProductInfoVo"/>
        </template>
      </BaseItemCom>
<!--   物流信息   -->
      <BaseItemCom title-text="物流信息（正向物流）">
        <template #itemInfo>
          <OrderLogisticsInfo :order-logistics-vo="orderLogisticsVo"/>
        </template>
        <template #itemBtn>
          <a-button
              type="danger"
              size="small"
              @click="handleChangeAddressOrLogistics(orderLogisticsVo, orderId, 'order')"
          >修改发货物流</a-button>
        </template>
      </BaseItemCom>
<!--   物流信息   -->
      <BaseItemCom title-text="物流信息（送评物流）" v-if="gradeLogisticsVo">
        <template #itemInfo>
          <OrderLogisticsInfo :order-logistics-vo="gradeLogisticsVo" :orderReturnVo="orderReturnVo"/>
        </template>
        <template #itemBtn>
          <a-button
              type="danger"
              size="small"
              @click="handleChangeAddressOrLogistics(gradeLogisticsVo, orderId, 'rateService')"
          >修改送评物流</a-button>
        </template>
      </BaseItemCom>
<!--   订单操作   -->
      <BaseItemCom title-text="订单操作（请谨慎操作）">
        <template #itemInfo>
          <OrderActionInfo
              :order-return-vo="orderReturnVo"
              :order-product-info-vo="orderProductInfoVo"
              @success="handleSuccess"
          />
        </template>
      </BaseItemCom>
<!--   日志，备注   -->
      <div class="flex-sp-bt-start">
        <BaseItemCom title-text="订单日志">
          <template #itemInfo>
            <OrderLogListInfo :orderLogVo="orderLogVo" />
          </template>
        </BaseItemCom>
        <BaseItemCom title-text="补充备注（仅内部客服人员可见，不对外展示。记录客服人员的备注信息）">
          <template #itemInfo>
            <OrderNoteListInfo :orderNoteList="orderNoteList" />
          </template>
          <template #itemBtn>
            <a-button @click="handleAddCenterNotes(orderProductInfoVo.id)">新增备注</a-button>
          </template>
        </BaseItemCom>
      </div>
    </a-drawer>
<!--  修改物流  -->
    <ChangeLogisticsMessage ref="changeLogisticsMessageEl" @change="handleSuccess" />
<!--  添加内部备注  -->
    <AddNotePopup ref="addNotePopupEl" @success="getServiceNoteList" />
  </div>
</template>
<script>
import _orderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_orderDetailMixin"
import BaseItemCom from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/BaseItemCom/index.vue"
import {orderTypeListMapText} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import ChangeLogisticsMessage from "@/views/cmsPage/dqPlatform/components/ChangeLogisticsMessage.vue"
import OrderBaseInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderBaseInfo/index.vue"
import OrderPayInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderPayInfo/index.vue"
import OrderLogisticsInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderLogisticsInfo/index.vue"
import OrderActionInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderActionInfo/index.vue"
import RateAboutInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/RateAboutInfo/index.vue"
import OrderLogListInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderLogListInfo/index.vue"
import OrderNoteListInfo
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderNoteListInfo/index.vue"
import AddNotePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderNoteListInfo/_components/AddNotePopup/index.vue"
export default {
  components: {
    BaseItemCom, OrderBaseInfo, OrderPayInfo, OrderLogisticsInfo, OrderActionInfo, ChangeLogisticsMessage,
    RateAboutInfo, OrderNoteListInfo, OrderLogListInfo, AddNotePopup
  },
  mixins: [_orderDetailMixin],
  emits: ['success'],
  data() {
    return {
      shopPopup: false,
      orderType: undefined,
      orderId: undefined, // 订单编号
      orderProductInfoVo: '', // 订单产品基本信息
      orderReturnVo: '', // 退款相关
      productSnapshot: '', // 商品信息相关
      orderLogisticsVo: '', // 物流相关--正向
      gradeLogisticsVo: '', // 物流相关---送评物流
      ratingInfoVo: '', // 评级信息
      orderLogVo: '', // 订单日志列表
      orderNoteList: '', // 订单日志列表
    }
  },
  computed: {
    computedOrderTypeListMapText() { // 订单类型
      return (value) => orderTypeListMapText(value)
    },
  },
  methods: {
    /** 新增内部备注 */
    handleAddCenterNotes(orderId) {
      this.$refs.addNotePopupEl.show(orderId)
    },
    /** 修改物流相关 */
    handleChangeAddressOrLogistics(data, orderId, type) {
      this.$refs.changeLogisticsMessageEl.show(data, orderId, type)
    },
    /** 展示弹窗 */
    async show(data) {
      this.orderType = data.orderKind
      this.orderId = data.orderProductId
      await this.getOrderDetailInfo()
      await this.getServiceNoteList()
    },
    /** 关闭弹窗 */
    handleClosePopup() {
      this.shopPopup = false
      if (this.orderProductInfoVo.orderRemainTimeLong) {
        // 关闭的时候清空计时器
        this.$refs.orderBaseInfoEl.handleClearTimer()
      }
      if (this.orderProductInfoVo.orderReturnRemainTime) {
        // 关闭的时候清空计时器
        this.$refs.orderBaseInfoEl.handleClearTimerByReturn()
      }
    },
    async handleSuccess() {
      await this.getOrderDetailInfo()
      this.$forceUpdate()
      this.$emit('success')
    }
  }
}
</script>