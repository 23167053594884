<template>
  <a-modal class="modal-box" v-model="showPopup" title="需要并保价" :width="700" @cancel="handleCancel" @ok="handleConfirm">
    <template #footer>
      <div class="flex-end-center">
        <a-button class="mr-20" type="default" @click="handleCancel">取消</a-button>
        <a-popconfirm title="确定执行当前操作吗？" @confirm="handleConfirm">
          <a-button type="primary">确定</a-button>
        </a-popconfirm>
      </div>
    </template>
    <div class="modal-content">
<!--  选择用户地址  -->
      <SelectedAddressListAbout
          v-if="detailInfo"
          :address-list="detailInfo.addressList"
          :params="params"
          @changeAddress="handleChangeAddress($event)"
      />
<!--  选择用户保价相关    -->
      <SelectedSavePriceAbout
          ref="selectedSavePriceAboutEl"
          v-if="detailInfo"
          :forecastPostage="forecastPostage"
          :params="params"
          :info="detailInfo"
      />
    </div>
  </a-modal>
</template>
<script>
import {
  getForecastPostagePriceApi,
  postConfirmBtnNeedAndSendProductApi,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import SelectedAddressListAbout
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/_compoents/ShowConfirmBtnNeedAndSendProductPopup/_components/SelectedAddressListAbout/index.vue"
import SelectedSavePriceAbout
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/_compoents/ShowConfirmBtnNeedAndSendProductPopup/_components/SelectedSavePriceAbout/index.vue"

export default {
  components: {SelectedAddressListAbout, SelectedSavePriceAbout},
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      detailInfo: undefined,
      forecastPostage: 0,
      params: {
        orderNo: undefined,
        addressId: undefined,
        insuredPrice: undefined,
        choiceStatus: 1,
      },
      apiUrl: '/dq_admin/recurRating/replaceUserSelection',
    }
  },
  methods: {
    show(info) {
      this.detailInfo = info
      this.params.orderNo = info.orderNo
      this.showPopup = true
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.addressId) return this.$message.warning('请为用户选择寄回地址')
      if (this.params.insuredPrice === undefined) return this.$message.warning('请为用户选择是否保价')
      this.$loading.show()
      const res = await postConfirmBtnNeedAndSendProductApi(this.params, this.apiUrl)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('success')
      this.handleCancel()
    },
    handleCancel() {
      this.showPopup = false
      this.params = {
        orderNo: undefined,
        addressId: undefined,
        insuredPrice: undefined,
        choiceStatus: 1,
      }
      this.$refs.selectedSavePriceAboutEl.handleCancelSelectSavePrice()
    },
    /** 改变地址 */
    async handleChangeAddress(item) {
      this.params.addressId = item.id
      const res = await getForecastPostagePriceApi(item.id)
      if (res.status !== '200') return
      this.forecastPostage = res.data.postagePrice
    }
  }
}
</script>
<style scoped lang="scss">
.modal-content {
  padding: 10px;
  background: #F4F6F6;
}
.modal-box::v-deep .ant-modal-body {
  padding: 0 !important;
}
</style>