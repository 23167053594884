import { render, staticRenderFns } from "./index.vue?vue&type=template&id=18bbeb11&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=18bbeb11&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18bbeb11",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhangshilei/Desktop/pro/dq-admin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18bbeb11')) {
      api.createRecord('18bbeb11', component.options)
    } else {
      api.reload('18bbeb11', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=18bbeb11&scoped=true&", function () {
      api.rerender('18bbeb11', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/_compoents/ShowConfirmBtnNeedAndSendProductPopup/_components/SelectedSavePriceAbout/index.vue"
export default component.exports