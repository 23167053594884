var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("a-input", {
          staticClass: "w-200 mb-5",
          attrs: { allowClear: "", placeholder: "订单号" },
          model: {
            value: _vm.params.orderNo,
            callback: function($$v) {
              _vm.$set(_vm.params, "orderNo", $$v)
            },
            expression: "params.orderNo"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "物流单号" },
          model: {
            value: _vm.params.expressNo,
            callback: function($$v) {
              _vm.$set(_vm.params, "expressNo", $$v)
            },
            expression: "params.expressNo"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "商品编号/名称" },
          model: {
            value: _vm.params.productName,
            callback: function($$v) {
              _vm.$set(_vm.params, "productName", $$v)
            },
            expression: "params.productName"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "买家/uId/昵称/店铺" },
          model: {
            value: _vm.params.buyerUserName,
            callback: function($$v) {
              _vm.$set(_vm.params, "buyerUserName", $$v)
            },
            expression: "params.buyerUserName"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "卖家/uId/昵称/店铺" },
          model: {
            value: _vm.params.sellerUserName,
            callback: function($$v) {
              _vm.$set(_vm.params, "sellerUserName", $$v)
            },
            expression: "params.sellerUserName"
          }
        }),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "商品等级" },
            model: {
              value: _vm.params.productReviewStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "productReviewStatus", $$v)
              },
              expression: "params.productReviewStatus"
            }
          },
          _vm._l(_vm.orderProductLevel, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "订单状态" },
            model: {
              value: _vm.params.status,
              callback: function($$v) {
                _vm.$set(_vm.params, "status", $$v)
              },
              expression: "params.status"
            }
          },
          _vm._l(_vm.orderStatusTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: {
              allowClear: "",
              showSearch: "",
              placeholder: "订单退款状态"
            },
            model: {
              value: _vm.params.refundStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "refundStatus", $$v)
              },
              expression: "params.refundStatus"
            }
          },
          _vm._l(_vm.orderReturnPriceStatus, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "订单类型" },
            model: {
              value: _vm.params.orderKind,
              callback: function($$v) {
                _vm.$set(_vm.params, "orderKind", $$v)
              },
              expression: "params.orderKind"
            }
          },
          _vm._l(_vm.orderTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c("a-range-picker", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { placeholder: ["订单创建日期", "订单结束日期"] },
          on: { change: _vm.handleChangeOrderCreateTime }
        }),
        _c("a-range-picker", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { placeholder: ["支付开始日期", "支付结束日期"] },
          on: { change: _vm.handleChangePayOverTime }
        }),
        _c(
          "a-checkbox",
          {
            staticClass: "ml-20",
            attrs: { checked: _vm.isSendProductTimeoutStatus },
            on: { change: _vm.handleChangeShowSendProductTimeoutStatus }
          },
          [_vm._v("即将发货超时")]
        ),
        _c(
          "a-button",
          {
            staticClass: "mb-5",
            attrs: { type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("TableList", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: { changePage: _vm.handleChangePage }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }