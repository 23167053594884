<template>
  <a-modal class="modal-box" v-model="showPopup" title="注意" :width="400" @cancel="handleCancel">
    <template #footer>
      <div class="flex-end-center">
        <a-button class="mr-20" type="default" @click="handleCancel">取消</a-button>
        <a-popconfirm title="确定执行当前操作吗？" @confirm="handleConfirm">
          <a-button type="primary">确定</a-button>
        </a-popconfirm>
      </div>
    </template>
    <div class="color-red">
      <a-icon type="info-circle" class="mr-10" />
      <span>{{ btnInfo.tipMessage }}</span>
      <div class="mt-10 color-red">谨慎操作！！！</div>
    </div>
  </a-modal>
</template>
<script>
import {
  postConfirmForSellerOrBuyerSelectActionApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"

export default {
  components: {},
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      popupData: '',
      btnInfo: ''
    }
  },
  methods: {
    show(btn, info) {
      this.popupData = info
      this.btnInfo = btn
      this.showPopup = true
    },
    handleCancel() {
      this.showPopup = false
      this.popupData = ''
      this.btnInfo = ''
    },
    async handleConfirm() {
      this.$loading.show()
      let params, apiUrl
      if (this.btnInfo.btnType === 'notNeed') {
        params = {
          orderNo: this.popupData.orderNo,
          choiceStatus: 3,
          insuredPrice: undefined,
          addressId: undefined,
        }
        apiUrl = '/dq_admin/recurRating/replaceUserSelection'
      } else if (this.btnInfo.btnType === 'needNotSendProduct') {
        params = { // TODO
          orderNo: this.popupData.orderNo,
        }
        apiUrl = ''
        return this.$message.error('暂未开放')
      }
      const res = await postConfirmForSellerOrBuyerSelectActionApi(params, apiUrl)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('success')
    }
  }
}
</script>
<style scoped lang="scss">
.popup-content {
  padding: 10px;
  background: #F4F6F6;
}
</style>