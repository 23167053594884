var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rate-bottom-button-list-about" },
    [
      _vm._l(_vm.info.buttonList, function(btn) {
        return _c(
          "a-button",
          {
            key: btn.btnType,
            staticClass: "mr-20",
            attrs: { type: btn.btnStyleType },
            on: {
              click: function($event) {
                return _vm.handleConfirmAction(btn)
              }
            }
          },
          [_vm._v(_vm._s(btn.btnText))]
        )
      }),
      _c("ShowConfirmBtnTipMessagePopup", {
        ref: "showConfirmBtnTipMessagePopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("ShowConfirmBtnNeedAndSendProductPopup", {
        ref: "showConfirmBtnNeedAndSendProductPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }