var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "modal-box",
      attrs: { title: "替买/卖家选择评级操作", width: 600 },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _vm.showPopup
            ? _c("SwitchSellerOrBuyerAbout", {
                attrs: {
                  params: _vm.params,
                  disabledRadioGroupStatus: _vm.disabledRadioGroupStatus
                },
                on: {
                  changeSellerOrBuyer: function($event) {
                    return _vm.handleChangeSellerOrBuyer($event)
                  }
                }
              })
            : _vm._e(),
          _vm.rateDetailInfo
            ? _c("RateTopTitleContentTextAbout", {
                attrs: { info: _vm.rateDetailInfo }
              })
            : _vm._e(),
          _vm.rateDetailInfo
            ? _c("RateResultAbout", {
                attrs: { rateResultInfo: _vm.rateDetailInfo.rateResult }
              })
            : _vm._e(),
          _vm.rateDetailInfo && _vm.rateDetailInfo.buttonList.length
            ? _c("RateBottomButtonListAbout", {
                attrs: { info: _vm.rateDetailInfo },
                on: { success: _vm.handleSuccess }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }