<template>
  <a-modal v-model="showPopup" :width='800' title="收款二维码" @cancel="handleCancel">
    <template #footer><div></div></template>
    <div class="qrcode-list">
      <div
          class="qrcode-item"
          v-for="(item, index) in qrCodeList"
          :key="index"
          @click="handleCopyQRCode(index, item)"
      >
        <div class="item-img" :ref="'qrContainer'+index">
          <img class="bg-img" :src="payTypeMapData[item.payMethod]" alt="">
          <div class="cover-box" v-if="item.payStatus">{{ item.payStatus }}</div>
          <VueQr class="vue-qr-temp" :text="item.gatewayUrl" :size="400" :logoSrc="logoUrl"  componentTag="canvas" />
          <div class="price-text">收款金额：{{ item.totalAmount }}</div>
          <div class="order-no">{{ item.orderNo }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import VueQr from 'vue-qr'
import { toPng } from 'html-to-image';

export default {
  components: {VueQr},
  emits: ['success'],
  data() {
    return {
      logoUrl: require('../../_static/logo.png'),
      showPopup: false,
      qrCodeList: [],
      payTypeMapData: {
        WECHAT_NATIVE: require('../../_static/wechat.png'),
        ALIPAY_NATIVE: require('../../_static/ali.png')
      }
    }
  },
  methods: {
    show(qrCodeList) {
      this.qrCodeList = qrCodeList
      this.showPopup = true
    },
    async handleCopyQRCode(index, item) {
      if (item.statusName) return this.$message.warning(`该二维码已${item.statusName}`)
      try {
        // 显示加载中
        this.$message.loading('正在生成图片...', 0);
        // 确保DOM已更新
        await this.$nextTick();
        console.log(toPng, '实例')

        // 获取二维码容器
        const qrContainer = this.$refs[`qrContainer${index}`][0];
        // 生成PNG图片
        const dataUrl = await toPng(qrContainer, {
          quality: 0.95,
          pixelRatio: 2,
          skipFonts: true,
          backgroundColor: '#ffffff'
        });

        // 复制到剪贴板
        await this.copyToClipboard(dataUrl, item);

        this.$message.destroy();
        this.$message.success('收款码已保存');
      } catch (error) {
        this.$message.destroy();
        console.error('复制失败:', error);

        // 降级方案：提示用户右键保存
        this.$message.warning('复制失败，点击收款码"保存二维码"');
      }
    },

    async copyToClipboard(dataUrl, item) {
      try {
        // 1. 首先检查Clipboard API是否可用
        if (!navigator.clipboard || !window.ClipboardItem) {
          throw new Error('浏览器不支持剪贴板API');
        }

        // 2. 将图片转换为Blob
        const blob = await this.dataUrlToBlob(dataUrl);

        // 3. 尝试写入剪贴板
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob })
        ]);

        return true;
      } catch (error) {
        console.warn('剪贴板API失败:', error);
        // 降级方案：使用execCommand（已废弃但仍有广泛支持）
        return this.fallbackCopy(dataUrl, item);
      }
    },

    async dataUrlToBlob(dataUrl) {
      const response = await fetch(dataUrl);
      return await response.blob();
    },

    async fallbackCopy(dataUrl, item) {
      return new Promise((resolve, reject) => {
        // 创建一个隐藏的textarea
        const textarea = document.createElement('textarea');
        textarea.value = dataUrl;
        textarea.style.position = 'fixed';
        textarea.style.opacity = 0;

        document.body.appendChild(textarea);
        textarea.select();

        try {
          document.body.removeChild(textarea);
          // 最终降级方案：下载图片
          this.downloadImage(dataUrl, item);
          resolve(false);
        } catch (error) {
          // 使用已废弃但支持更广的execCommand
          const success = document.execCommand('copy');
          document.body.removeChild(textarea);

          if (success) {
            this.$message.info('收款码已复制');
            resolve(true);
          } else {
            throw new Error('复制失败');
          }
        }
      });
    },

    downloadImage(dataUrl, item) {
      const link = document.createElement('a');
      link.download = `${item.order}_${item.price}.png`;
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$message.info('收款码已保存，请手动使用');
    },
    /** 取消 */
    handleCancel() {
      this.qrCodeList = []
      this.showPopup = false
    },
  }
}
</script>
<style scoped lang="scss">
.qrcode-item::after {
  content: '点击下载图片';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.qrcode-item {
  margin-bottom: 30px;
}
.item-img {
  position: relative;
  width: 342px;
  height: 464px;
  cursor: pointer;
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 342px;
    height: 464px;
  }
  .price-text {
    position: absolute;
    left: 50%;
    top: 325px;
    z-index: 2;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 20px;
    transform: translateX(-50%);
  }
  .order-no {
    position: absolute;
    left: 50%;
    top: 355px;
    z-index: 2;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 14px;
    transform: translateX(-50%);
  }
  .cover-box {
    position: absolute;
    top: 100px;
    left: 50%;
    z-index: 3;
    width: 200px !important;
    height: 200px !important;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
    font-weight: bold;
    font-size: 40px;
  }
  .vue-qr-temp {
    position: absolute;
    top: 100px;
    left: 50%;
    z-index: 2;
    width: 200px !important;
    height: 200px !important;
    transform: translateX(-50%);
  }
}
.qrcode-list {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f2f2f2;
}
</style>