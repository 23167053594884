<template>
  <div>
    <div class="flex-start-center flex-wrap mt-20">
      <div class="cell-box mr-40">订单原价/中拍价：<span class="font-weight-bold color-red">￥{{ orderProductInfoVo.orderPrice }}</span></div>
      <div class="cell-box mr-40">买家手续费：<span class="font-weight-bold color-red">￥{{ orderProductInfoVo.buyerServiceMoney }}</span></div>
      <div class="cell-box mr-40">卖家手续费：<span class="font-weight-bold color-red">￥{{ orderProductInfoVo.sellerServiceMoney }}</span></div>
      <div class="cell-box mr-40">运费：<span class="font-weight-bold color-red">￥{{ orderProductInfoVo.logisticsFee }}</span></div>
      <div class="cell-box mr-40">买家实际支付金额：<span class="font-weight-bold color-red">￥{{ orderProductInfoVo.orderTotalPrice || '包邮' }}</span></div>
      <div class="cell-box mr-40">支付方式：<span class="font-weight-bold" :class="payTypeListClassType[orderProductInfoVo.payType]">{{ computedPayTypeListMapText(orderProductInfoVo.payType) }}</span></div>
      <div class="cell-box mr-40">支付时间：<span class="font-weight-bold" >{{orderProductInfoVo.payTime }}</span></div>
    </div>
  </div>
</template>
<script>
import {payTypeListClassType, payTypeListMapType} from "@/views/cmsPage/dqPlatform/_data"

export default {
  props: ['orderProductInfoVo'],
  data() {
    return {
      payTypeListClassType
    }
  },
  computed: {
    computedPayTypeListMapText() { // 支付类型
      return (value) => payTypeListMapType(value)
    },
  },
  methods: {

  }
}
</script>