var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 600, title: "创建退货退款申请" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "退货退款原因" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    placeholder: "选择退货原因"
                  },
                  on: { change: _vm.handleChangeRefundType },
                  model: {
                    value: _vm.params.refundType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "refundType", $$v)
                    },
                    expression: "params.refundType"
                  }
                },
                _vm._l(
                  _vm.createApplyReturnPriceOrReturnProductReasonList,
                  function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }
                ),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.computedDepositTitle } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup", "default-value": 0 },
                  on: { change: _vm.handleChangeRadio }
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("扣除")]),
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不扣除")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否扣除手续费" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup", "default-value": 1 },
                  on: { change: _vm.handleChangeRadioByHandleFees }
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("扣除")]),
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不扣除")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "退款类型" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup", "default-value": -1 },
                  on: { change: _vm.handleChangeRadioReturnAndRefundsType }
                },
                [
                  _c("a-radio", { attrs: { value: "goodsnotreceived" } }, [
                    _vm._v("仅退款")
                  ]),
                  _c("a-radio", { attrs: { value: "goodsreceived" } }, [
                    _vm._v("退货退款")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.computedLogisticsFeeTitle } },
            [
              _c("a-input", {
                attrs: {
                  type: "number",
                  placeholder: _vm.computedLogisticsFeeTitle
                },
                on: { change: _vm.handleChangeRefundType },
                model: {
                  value: _vm.params.expressMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "expressMoney", $$v)
                  },
                  expression: "params.expressMoney"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.computedExtraFeeTitle } },
            [
              _c("a-input", {
                attrs: {
                  type: "number",
                  placeholder: _vm.computedExtraFeeTitle
                },
                on: { change: _vm.handleChangeRefundType },
                model: {
                  value: _vm.params.certificateMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "certificateMoney", $$v)
                  },
                  expression: "params.certificateMoney"
                }
              })
            ],
            1
          ),
          _vm.userRateData
            ? _c("a-form-item", { attrs: { label: "对应补偿信息" } }, [
                _c("div", { staticClass: "color-red font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.userRateData.couponDesc))
                ]),
                _c("div", { staticClass: "color-red font-weight-bold mt-5" }, [
                  _vm._v(_vm._s(_vm.userRateData.depositDesc))
                ]),
                _c("div", { staticClass: "color-red font-weight-bold mt-5" }, [
                  _vm._v(_vm._s(_vm.userRateData.serviceMoneyDesc))
                ]),
                _c("div", { staticClass: "color-red font-weight-bold mt-5" }, [
                  _vm._v(_vm._s(_vm.userRateData.incomePersonStr))
                ])
              ])
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "补充说明" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  type: "textarea",
                  "auto-size": { minRows: 4, maxRows: 10 },
                  placeholder: "补充说明"
                },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "上传凭证" } },
            [
              _c(
                "a-upload",
                {
                  key: new Date().getTime(),
                  attrs: {
                    action: _vm.ip + "/file/upload",
                    "list-type": "picture-card",
                    "file-list": _vm.picList
                  },
                  on: {
                    preview: function($event) {
                      return _vm.handlePreview()
                    },
                    change: _vm.handleChange
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _c("div", { staticClass: "ant-upload-text" }, [
                        _vm._v("上传图片")
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }