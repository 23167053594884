<template>
  <div class="rate-result-about">
    <div class="title-box">
      <div class="title-text">{{ rateResultInfo.title }}</div>
      <div v-if="rateResultInfo.huaXiaUrl" class="hua-xia-title cur-pot" @click="handleGoToHuaXiaSearchCode">查看华夏官网 ></div>
      <div v-else></div>
    </div>
    <div class="rate-result-list">
      <div class="rate-result-item" v-for="(item, index) in rateResultInfo.resultLineList" :key="index">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-value">{{ item.value }}</div>
      </div>
    </div>
    <div class="rate-result-img-list">
      <div
          class="img-item"
          v-for="(img, index) in rateResultInfo.resultImgList"
          :key="index"
          @click="handleShowBigImg(rateResultInfo.resultImgList, index)"
      >
        <img :src="img" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"

export default {
  mixins: [_showBigImgMixin],
  props: ['rateResultInfo'],
  data() {
    return {}
  },
  methods: {
    /** 去往华夏官网 */
    handleGoToHuaXiaSearchCode() {
      window.open(this.rateResultInfo.huaXiaUrl, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.rate-result-img-list {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .img-item {
    margin-right: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }
  }
}
.rate-result-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .rate-result-item {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .item-value {
      margin-left: 10px;
      font-weight: bold;
      font-size: 14px;
      color: #2C2C2C;
    }
    .item-title {
      width: 70px;
      font-weight: 400;
      font-size: 14px;
      color: #9B9B9B;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
.title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .hua-xia-title {
    font-weight: 400;
    font-size: 12px;
    color: #9B9B9B;
  }
  .title-text {
    font-weight: bold;
    font-size: 16px;
    color: #2C2C2C;
  }
}
.rate-result-about {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
</style>