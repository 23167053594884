<template>
  <a-modal class="modal-box" v-model="showPopup" title="替买/卖家选择评级操作" :width="600" @cancel="handleCancel">
<!--  底部按钮  -->
    <template #footer><div></div></template>
    <div class="popup-content">
<!--   顶部切换买家，卖家   -->
      <SwitchSellerOrBuyerAbout
          v-if="showPopup"
          :params="params"
          :disabledRadioGroupStatus="disabledRadioGroupStatus"
          @changeSellerOrBuyer="handleChangeSellerOrBuyer($event)"
      />
<!--   顶部标题--描述   -->
      <RateTopTitleContentTextAbout v-if="rateDetailInfo" :info="rateDetailInfo"/>
<!--   中部评级结果相关   -->
      <RateResultAbout v-if="rateDetailInfo" :rateResultInfo="rateDetailInfo.rateResult"/>
<!--   底部按钮   -->
      <RateBottomButtonListAbout
          v-if="rateDetailInfo && rateDetailInfo.buttonList.length"
          :info="rateDetailInfo"
          @success="handleSuccess"
      />
    </div>
  </a-modal>
</template>
<script>
import {
  getForSellerOrBuyerSelectRateActionApi,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import SwitchSellerOrBuyerAbout
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/SwitchSellerOrBuyerAbout/index.vue"
import RateTopTitleContentTextAbout
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateTopTitleContentTextAbout/index.vue"
import RateResultAbout
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateResultAbout/index.vue"
import RateBottomButtonListAbout
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/ForBuyerOrSellerSelectRateActionPopup/_components/RateBottomButtonListAbout/index.vue"

export default {
  components: {
    SwitchSellerOrBuyerAbout, RateTopTitleContentTextAbout, RateResultAbout, RateBottomButtonListAbout
  },
  props: ['rateType'],
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      params: {
        orderNo: undefined,
        type: 1, // 1: 买家，2: 卖家
      },
      disabledRadioGroupStatus: false,
      rateDetailInfo: undefined
    }
  },
  methods: {
    /** 展示弹窗 */
    async show(data) {
      this.params.orderNo = data.orderNo
      await this.getSellerOrBuyerSelectActionDetailInfo()
      this.showPopup = true
    },
    /** 获取替用户选择数据 */
    async getSellerOrBuyerSelectActionDetailInfo() {
      this.$loading.show()
      this.disabledRadioGroupStatus = true
      const res = await getForSellerOrBuyerSelectRateActionApi(this.params)
      this.$loading.hide()
      this.disabledRadioGroupStatus = false
      if (res.status !== '200') return
      this.rateDetailInfo = res.data
      this.rateDetailInfo.orderNo = this.params.orderNo
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderNo: undefined,
        type: 1, // 1: 买家，2: 卖家
      }
      this.rateDetailInfo = undefined
      this.showPopup = false
    },
    /** 切换买家，卖家 */
    async handleChangeSellerOrBuyer(type) {
      this.params.type = type
      await this.getSellerOrBuyerSelectActionDetailInfo()
    },
    handleSuccess() {
      this.$message.info('操作成功')
      this.handleCancel()
      this.$emit('success')
    }
  }
}
</script>
<style scoped lang="scss">
.popup-content {
  padding: 10px;
  background: #F4F6F6;
}
.modal-box::v-deep .ant-modal-body {
  padding: 0 !important;
}
</style>