<template>
  <a-modal v-model="showPopup" :width='700' title="大额订单拆分线下支付" @cancel="handleCancel">
    <template #footer>
      <div></div>
    </template>
    <div class="popup-content">
      <a-form-model v-bind="layout">
<!--    支付方式    -->
        <a-form-item label="支付方式">
          <a-radio-group v-model="params.payType" button-style="solid">
            <a-radio-button :value="1">微信支付</a-radio-button>
            <a-radio-button :value="2">支付宝支付</a-radio-button>
          </a-radio-group>
        </a-form-item>
<!--    是否评级    -->
        <a-form-item label="是否评级">
          <a-radio-group v-model="params.ratingType" button-style="solid" @change="handleChangeRateStatus">
            <a-radio-button :value="0">无需评级</a-radio-button>
            <a-radio-button :value="1">需要评级</a-radio-button>
          </a-radio-group>
        </a-form-item>
<!--    是否标版    -->
        <a-form-item label="是否标版" v-if="params.ratingType !== undefined">
          <a-radio-group
              v-model="params.biaobanType"
              :disabled="params.ratingType === 0"
              button-style="solid"
              @change="getNeedPrice"
          >
            <a-radio-button :value="0">无需标版</a-radio-button>
            <a-radio-button :value="1">需要标版</a-radio-button>
          </a-radio-group>
        </a-form-item>
<!--  应付金额    -->
        <a-form-item label="描述">
          <div class="font-weight-bold font-size-14 cur-pot">{{ tipDesc || '请先选择评级或标版' }}</div>
        </a-form-item>
        <a-form-item label="应收总金额描述">
          <div class="font-weight-bold font-size-14 cur-pot">{{ tipTotalPriceText || '请先选择评级或标版' }}</div>
        </a-form-item>
        <a-form-item label="应收总金额">
          <div class="font-weight-bold color-blue font-size-16 cur-pot">{{ needPrice || '请先选择评级或标版' }}</div>
        </a-form-item>
<!--   拆分金额     -->
        <a-form-item label="拆分金额" v-if="needPrice">
          <div class="flex-start-center mb-10" v-for="(item, index) in params.priceList" :key="index">
            <a-input-number
                :disabled="params.priceList.length - 1 > index"
                class="w-200"
                v-model="params.priceList[index]"
                :min="0" :max="needPrice"
                :step="0.01"
            />
            <a-button
                v-if="params.priceList.length - 1 === index && computedRestPrice > 0"
                @click="handleAddSplitPrice(index)"
                class="ml-20"
                type="primary"
                icon="plus"></a-button>
            <a-button
                v-if="params.priceList.length > 1"
                @click="handleDelSplitPrice(index)"
                class="ml-20"
                type="danger"
                icon="minus"></a-button>
            <div class="ml-20 font-weight-bold" v-if="params.priceList.length - 1 === index">
              剩余应收金额：
              <span
                  class="cur-pot"
                  :class="computedRestPrice < 0 ? 'color-red' : 'color-blue'"
              >{{ computedRestPrice }}</span>
            </div>
          </div>
        </a-form-item>
<!--    操作按钮    -->
        <a-form-item label="操作" v-if="needPrice && computedRestPrice === 0">
          <a-button type="primary" @click="handleGetQrCode">获取收款二维码</a-button>
        </a-form-item>
      </a-form-model>
    </div>
<!--  二维码  -->
    <CollectionQRCodePopup ref="collectionQRCodePopupEl"/>
  </a-modal>
</template>
<script>

import {
  getBigPriceOrderNeedPriceApi, getReceivablesQrcodeApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderSplitPayPopup/_apis"
import {subtract} from "@/untils/otherEvent"
import CollectionQRCodePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/OrderDetailPopup/_componetns/OrderSplitPayPopup/_components/CollectionQRCodePopup/index.vue"

export default {
  components: {CollectionQRCodePopup},
  emits: ['success'],
  data() {
    return {
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      showPopup: false,
      params: {
        orderId: undefined,
        payType: 1, // 支付类型 1微信，2支付宝
        ratingType: undefined, // 评级状态，
        biaobanType: 0, // 标版状态
        priceList: [ 0 ]
      },
      needPrice: 0, // 应付金额
      tipDesc: '', // 描述
      tipTotalPriceText: '', // 总金额规则
    }
  },
  computed: {
    // 计算剩余金额
    computedRestPrice() {
      let total = 0
      if (this.params.priceList.length >= 1) {
        this.params.priceList.forEach(item => {
          total += item || 0
        })
      }
      return subtract(this.needPrice, total)
    }
  },
  methods: {
    show(productInfo, orderId) {
      this.params.orderId = orderId
      this.showPopup = true
    },
    /** 获取收款二维码 */
    async handleGetQrCode() {
      this.$loading.show()
      console.log(this.params)
      const res = await getReceivablesQrcodeApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$refs.collectionQRCodePopupEl.show(res.data)
    },
    /** 添加拆分金额 */
    handleAddSplitPrice(index) {
      if (this.params.priceList[index] <= 0) return this.$message.warning('请输入金额')
      this.params.priceList.push(0)
    },
    /** 删除拆分金额 */
    handleDelSplitPrice(index) {
      this.params.priceList.splice(index, 1)
    },
    /** 改变是否需要评级 */
    async handleChangeRateStatus(e) {
      if (e.target.value === 0) {
        this.params.biaobanType = 0
      }
      await this.getNeedPrice()
    },
    /** 获取应付金额 */
    async getNeedPrice() {
      console.log(this.params)
      const res = await getBigPriceOrderNeedPriceApi(this.params)
      if (res.status !== '200') return
      this.tipDesc = res.data.desc
      this.tipTotalPriceText = res.data.shouldTotalPriceFormula
      this.needPrice = res.data.shouldTotalPrice * 1
      this.$emit('success')
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        payType: 1, // 支付类型 1微信，2支付宝
        ratingType: undefined, // 评级状态，
        biaobanType: 0, // 标版状态
        priceList: [ 0 ]
      }
      this.needPrice = 0 // 应付金额
      this.tipDesc = '' // 描述
      this.tipTotalPriceText = '' // 总金额规则
      this.showPopup = false
    },
  }
}
</script>