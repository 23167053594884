var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selected-address-list" }, [
    _c("div", { staticClass: "title-text" }, [_vm._v("请选择地址")]),
    _c(
      "div",
      { staticClass: "address-list" },
      _vm._l(_vm.addressList, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "address-item",
            class:
              _vm.params.addressId === item.id ? "active-address-item" : "",
            on: {
              click: function($event) {
                return _vm.handleChangeAddress(item)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "address-top",
                class:
                  _vm.params.addressId === item.id ? "active-item-text" : ""
              },
              [
                _c("a-icon", { attrs: { type: "environment" } }),
                _c("span", { staticClass: "ml-5 mr-5" }, [
                  _vm._v(_vm._s(item.userName))
                ]),
                _c("span", [_vm._v(_vm._s(item.telNumber))])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "address-bottom",
                class:
                  _vm.params.addressId === item.id ? "active-item-text" : ""
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      item.provinceName +
                        item.cityName +
                        item.countyName +
                        item.detailInfo
                    ) +
                    " "
                )
              ]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }