var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold",
                    class: _vm.orderTypeClassType[row.orderKind]
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.computedOrderTypeListMapText(row.orderKind))
                    )
                  ]
                ),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("【"),
                  _c("span", { staticClass: "color-blue" }, [
                    _vm._v(_vm._s(row.orderNo))
                  ]),
                  _vm._v("】")
                ])
              ])
            }
          },
          {
            key: "itemProductNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.productReviewStatus
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.orderProductLevelClassType[
                            row.productReviewStatus
                          ]
                      },
                      [
                        _vm._v(
                          "【" +
                            _vm._s(
                              _vm.computedOrderProductLevelMapText(
                                row.productReviewStatus
                              )
                            ) +
                            "】"
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-10 font-weight-bold color-black" },
                  [_vm._v(_vm._s(row.productName))]
                )
              ])
            }
          },
          {
            key: "itemProductImgListSlot",
            fn: function(row) {
              return _c(
                "div",
                { staticClass: "flex-start-center flex-wrap" },
                _vm._l(row.snapShootPicList, function(item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "lazy-container",
                          rawName: "v-lazy-container",
                          value: { selector: "img" },
                          expression: "{ selector: 'img' }"
                        }
                      ],
                      key: index
                    },
                    [
                      _c("img", {
                        staticClass: "w-100 h-100",
                        attrs: { "data-src": item },
                        on: {
                          click: function($event) {
                            return _vm.handleShowBigImg(
                              row.snapShootPicList,
                              index
                            )
                          }
                        }
                      })
                    ]
                  )
                }),
                0
              )
            }
          },
          {
            key: "itemOrderPriceAndPayTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-red" }, [
                  _vm._v("￥" + _vm._s(row.orderPrice))
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("【"),
                  _c("span", { class: _vm.payTypeListClassType[row.payType] }, [
                    _vm._v(_vm._s(_vm.computedPayTypeListMapText(row.payType)))
                  ]),
                  _vm._v("】")
                ])
              ])
            }
          },
          {
            key: "itemOrderStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v("订单状态【"),
                  _c(
                    "span",
                    { class: _vm.orderStatusTypeListClassType[row.status] },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedOrderStatusTypeListMapText(row.status) ||
                            "未知"
                        )
                      )
                    ]
                  ),
                  _vm._v("】")
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("评级类型【"),
                  _c(
                    "span",
                    {
                      class: _vm.rateServiceTypeStatusClassType[row.serviceType]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedRateServiceTypeStatusListMapText(
                            row.serviceType
                          ) || "其他"
                        )
                      )
                    ]
                  ),
                  _vm._v("】")
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("退款状态【"),
                  _c(
                    "span",
                    {
                      class:
                        _vm.orderReturnPriceStatusClassType[row.refundStatus]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedOrderReturnPriceStatusMapText(
                            row.refundStatus
                          ) || "暂无"
                        )
                      )
                    ]
                  ),
                  _vm._v("】")
                ])
              ])
            }
          },
          {
            key: "itemBuyerInfoOrSellerInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v("买家信息【"),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v(_vm._s(row.buyerUserName + "---" + row.buyerUserId))
                  ]),
                  _vm._v("】")
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("卖家信息【"),
                  _c("span", { staticClass: "color-blue" }, [
                    _vm._v(
                      _vm._s(row.sellerUserName + "---" + row.sellerUserId)
                    )
                  ]),
                  _vm._v("】")
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowOrderDetail(row)
                        }
                      }
                    },
                    [_vm._v("订单详情")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("OrderDetailPopup", {
        ref: "orderDetailPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }