<template>
  <div class="content">
    <div class="top">
      <a-input allowClear v-model="params.orderNo" class="w-200 mb-5" placeholder='订单号'></a-input>
      <a-input allowClear v-model="params.expressNo" class="ml-5 w-200 mb-5" placeholder='物流单号'></a-input>
      <a-input allowClear v-model="params.productName" class="ml-5 w-200 mb-5" placeholder='商品编号/名称'></a-input>
      <a-input allowClear v-model="params.buyerUserName" class="ml-5 w-200 mb-5" placeholder='买家/uId/昵称/店铺'></a-input>
      <a-input allowClear v-model="params.sellerUserName" class="ml-5 w-200 mb-5" placeholder='卖家/uId/昵称/店铺'></a-input>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.productReviewStatus" placeholder="商品等级">
        <a-select-option v-for="item of orderProductLevel" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.status" placeholder="订单状态">
        <a-select-option v-for="item of orderStatusTypeList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.refundStatus" placeholder="订单退款状态">
        <a-select-option v-for="item of orderReturnPriceStatus" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.orderKind" placeholder="订单类型">
        <a-select-option v-for="item of orderTypeList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-range-picker :placeholder="['订单创建日期','订单结束日期']" class="ml-5 w-200 mb-5" @change="handleChangeOrderCreateTime"/>
      <a-range-picker :placeholder="['支付开始日期','支付结束日期']" class="ml-5 w-200 mb-5" @change="handleChangePayOverTime"/>
      <a-checkbox class="ml-20" :checked='isSendProductTimeoutStatus' @change="handleChangeShowSendProductTimeoutStatus">即将发货超时</a-checkbox>
      <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_components/TableList/index.vue"
import {getOrderListApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import {
  orderProductLevel, orderReturnPriceStatus, orderStatusTypeList,
  orderTypeList,
  rateServiceTypeStatusList
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/_data"
import _orderListMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_orderListMixin"

export default {
  mixins: [_orderListMixin],
  components: {
    TableList,
  },
  data() {
    return {
      data: [],
      isSendProductTimeoutStatus: false,  // 是否搜索订单即将截拍
      orderStatusTypeList, // 订单状态列表
      orderReturnPriceStatus, // 订单退款状态
      rateServiceTypeStatusList, // 订单服务类型状态
      orderProductLevel, // 商品等级
      orderTypeList, // 订单类型
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: false,
        pageSizeOptions: ['10', '50', '100', '150', '200'],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        orderNo: undefined, // 订单号
        expressNo: undefined, // 物流单号
        productName: undefined, // 商品编号/名称
        buyerUserName: undefined, // 买家/uId/昵称/店铺
        sellerUserName: undefined, // 卖家/uId/昵称/店铺
        status: undefined, // 订单状态
        refundStatus: undefined, // 订单退款状态
        orderKind: undefined, // 订单类型
        createStartTime: undefined, // 订单创建时间
        createEndTime: undefined, // 订单结束时间
        payStartTime: undefined, // 支付开始时间
        payEndTime: undefined, // 支付结束时间
        productReviewStatus: undefined, // 商品等级
        sendStatus: undefined, // 订单是否即将超时
      },
    };
  },
  async created() {
    await this.changeOrderListApiPageSizeByZJY() // 判断是否远哥修改订单列表每页数据
    const { orderNo } = this.$route.query;
    this.$set(this.params, "orderNo", orderNo);
    await this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    /** 搜索 */
    search() {
      this.pagination.params = 1
      this.params.pageNum = 1
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getOrderListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
