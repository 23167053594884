var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-title-content-text-about" }, [
    _c("div", { staticClass: "title-text" }, [
      _vm._v(_vm._s(_vm.info.titleText))
    ]),
    _vm.info.contentText
      ? _c("div", {
          staticClass: "desc-text",
          domProps: { innerHTML: _vm._s(_vm.info.contentText) }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }