var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
        _c("div", { staticClass: "cell-box" }, [
          _vm._v("订单编号："),
          _c("span", { staticClass: "font-weight-bold color-dq-blue" }, [
            _vm._v(_vm._s(_vm.orderProductInfoVo.orderNo))
          ])
        ]),
        _c("div", { staticClass: "ml-40 cell-box" }, [
          _vm._v("买家："),
          _c("span", { staticClass: "font-weight-bold color-dq-blue" }, [
            _vm._v(_vm._s(_vm.orderProductInfoVo.buyerUserName))
          ])
        ]),
        _c("div", { staticClass: "ml-40 cell-box" }, [
          _vm._v("卖家："),
          _c("span", { staticClass: "font-weight-bold color-dq-blue" }, [
            _vm._v(_vm._s(_vm.orderProductInfoVo.sellerUserName))
          ])
        ]),
        _c("div", { staticClass: "ml-40 cell-box" }, [
          _vm._v("下单/截拍时间："),
          _c("span", [_vm._v(_vm._s(_vm.orderProductInfoVo.createTime))])
        ]),
        _c(
          "div",
          { staticClass: "ml-40 cell-box flex-start-center" },
          [
            _vm._v(" 订单正向主状态： "),
            _c(
              "span",
              {
                staticClass: "font-weight-bold color-black",
                class:
                  _vm.orderStatusTypeListClassType[
                    _vm.orderProductInfoVo.status
                  ]
              },
              [_vm._v(_vm._s(_vm.orderProductInfoVo.statusName))]
            ),
            _vm.orderProductInfoVo.offlineStatus
              ? _c("span", { staticClass: "font-weight-bold color-red" }, [
                  _vm._v("（线下交易）")
                ])
              : _vm._e(),
            _vm.orderProductInfoVo.orderRemainTimeLong &&
            _vm.showCountTimeStatus
              ? _c(
                  "span",
                  { staticClass: "flex-start-center" },
                  [
                    _vm._v(" （"),
                    _c("BaseCountdownCom", {
                      ref: "baseCountdownComEl",
                      attrs: {
                        "remaining-time":
                          _vm.orderProductInfoVo.orderRemainTimeLong
                      }
                    }),
                    _vm._v("） ")
                  ],
                  1
                )
              : _vm._e(),
            _vm.orderProductInfoVo.status === 0 &&
            _vm.qrCodeList &&
            _vm.qrCodeList.length
              ? _c(
                  "a-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.handleShowCollectionQRCodePopup(
                          _vm.qrCodeList
                        )
                      }
                    }
                  },
                  [_vm._v("查看大额订单拆分收款码")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm.orderProductInfoVo.refundStatus
          ? _c(
              "div",
              { staticClass: "ml-40 cell-box flex-start-center" },
              [
                _vm._v(" 订单逆向子状态： "),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold color-black",
                    class:
                      _vm.returnReviewStatusListClassType[
                        _vm.orderReturnVo.returnsReviewStatus
                      ]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedReturnReviewStatusListMapText(
                          _vm.orderReturnVo.returnsReviewStatus
                        )
                      )
                    )
                  ]
                ),
                _vm.orderProductInfoVo.orderReturnRemainTimeLong &&
                _vm.showCountTimeStatus
                  ? _c(
                      "span",
                      { staticClass: "flex-start-center" },
                      [
                        _vm._v(" （"),
                        _c("BaseCountdownCom", {
                          ref: "baseCountdownComByReturnEl",
                          attrs: {
                            "remaining-time":
                              _vm.orderProductInfoVo.orderReturnRemainTimeLong
                          }
                        }),
                        _vm._v("） ")
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "a-button",
                  {
                    attrs: { size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.handleGoToReturnOrderList(
                          _vm.orderProductInfoVo.orderNo
                        )
                      }
                    }
                  },
                  [_vm._v("查看退款单")]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.productSnapshot
        ? _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
            _c("div", { staticClass: "cell-box mr-40" }, [
              _vm._v("商品名称："),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.productSnapshot.productName))
              ])
            ]),
            _c("div", { staticClass: "cell-box mr-40" }, [
              _vm._v("商品介绍："),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.productSnapshot.content || "无"))
              ])
            ]),
            _c("div", { staticClass: "cell-box mr-40" }, [
              _vm._v("商品瑕疵："),
              _c("span", { staticClass: "font-weight-bold color-red" }, [
                _vm._v(
                  _vm._s(
                    _vm.computedProductRemark(_vm.productSnapshot.productRemark)
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "cell-box mr-40" }, [
              _vm._v(" 评级公司： "),
              _c("span", { staticClass: "font-weight-bold color-dq-blue" }, [
                _vm._v(_vm._s(_vm.productSnapshot.ratingCompanyName))
              ]),
              _vm.productSnapshot.productCode
                ? _c(
                    "span",
                    { staticClass: "font-weight-bold color-dq-blue" },
                    [
                      _vm._v(
                        "【" + _vm._s(_vm.productSnapshot.productCode) + "】"
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "cell-box mr-40" }, [
              _vm._v("心理价："),
              _c("span", { staticClass: "font-weight-bold color-red" }, [
                _vm._v("￥" + _vm._s(_vm.productSnapshot.finalPrice))
              ])
            ]),
            _c("div", { staticClass: "cell-box mr-40" }, [
              _vm._v("尺寸重量："),
              _c("span", { staticClass: "font-weight-bold color-dq-blue" }, [
                _vm._v(_vm._s(_vm.productSnapshot.productSize))
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
        _c(
          "div",
          { staticClass: "cell-box flex-start-center flex-wrap" },
          [
            _vm._v(" 商品图片： "),
            _vm._l(_vm.productSnapshot.files, function(img, index) {
              return _c(
                "div",
                { key: index, staticClass: "w-50 h-50 flex-center-center" },
                [
                  _c("img", {
                    staticClass: "w-100-w h-100-h",
                    attrs: { src: img, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handleShowBigImg(
                          _vm.productSnapshot.files,
                          index
                        )
                      }
                    }
                  })
                ]
              )
            })
          ],
          2
        )
      ]),
      _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
        _vm._v(" 卖家备注信息："),
        _c("span", { staticClass: "font-weight-bold color-red" }, [
          _vm._v(_vm._s(_vm.productSnapshot.notes || "无"))
        ])
      ]),
      _c("CollectionQRCodePopup", { ref: "collectionQRCodePopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }