import instance from "@/axios"

/** 获取到大额订单的应付金额 */
export const getBigPriceOrderNeedPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/lianlianQrcode/getToBePaidPrice',
        method: 'post',
        data: params
    })
}

/** 获取收款二维码 */
export const getReceivablesQrcodeApi = async (params) => {
    return instance({
        url: '/dq_admin/lianlianQrcode/tradeCreate',
        method: 'post',
        data: params
    })
}