var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "收款二维码" },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "qrcode-list" },
        _vm._l(_vm.qrCodeList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "qrcode-item",
              on: {
                click: function($event) {
                  return _vm.handleCopyQRCode(index, item)
                }
              }
            },
            [
              _c(
                "div",
                {
                  ref: "qrContainer" + index,
                  refInFor: true,
                  staticClass: "item-img"
                },
                [
                  _c("img", {
                    staticClass: "bg-img",
                    attrs: { src: _vm.payTypeMapData[item.payMethod], alt: "" }
                  }),
                  item.payStatus
                    ? _c("div", { staticClass: "cover-box" }, [
                        _vm._v(_vm._s(item.payStatus))
                      ])
                    : _vm._e(),
                  _c("VueQr", {
                    staticClass: "vue-qr-temp",
                    attrs: {
                      text: item.gatewayUrl,
                      size: 400,
                      logoSrc: _vm.logoUrl,
                      componentTag: "canvas"
                    }
                  }),
                  _c("div", { staticClass: "price-text" }, [
                    _vm._v("收款金额：" + _vm._s(item.totalAmount))
                  ]),
                  _c("div", { staticClass: "order-no" }, [
                    _vm._v(_vm._s(item.orderNo))
                  ])
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }