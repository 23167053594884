<template>
  <div class="selected-address-list">
    <div class="title-text">请选择地址</div>
    <div class="address-list">
      <div
          class="address-item"
          v-for="item in addressList"
          :key="item.id"
          :class="params.addressId === item.id ? 'active-address-item' : ''"
          @click="handleChangeAddress(item)"
      >
        <div class="address-top" :class="params.addressId === item.id ? 'active-item-text' : ''">
          <a-icon type="environment" />
          <span class="ml-5 mr-5">{{ item.userName }}</span>
          <span>{{ item.telNumber }}</span>
        </div>
        <div class="address-bottom" :class="params.addressId === item.id ? 'active-item-text' : ''">
          {{ item.provinceName + item.cityName + item.countyName +  item.detailInfo }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['params', 'addressList'],
  emits: ['changeAddress'],
  data() {
    return {}
  },
  methods: {
    handleChangeAddress(item) {
      this.$emit('changeAddress', item)
    }
  }
}
</script>
<style scoped lang="scss">
.address-bottom {
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #9B9B9B;
  line-height: 16px;
}
.address-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #2C2C2C;
}
.address-item {
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  height: 80px;
  width: 300px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  cursor: pointer;
}
.address-list {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.selected-address-list {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}
.title-text {
  font-weight: bold;
  font-size: 16px;
  color: #2C2C2C;
}
.active-address-item {
  border: 2px solid #32788A !important;
  background: #EAF1F2 !important;
}
.active-item-text {
  color: #32788A !important;
  font-weight: bold;
}
</style>