<template>
  <div class="top-title-content-text-about">
    <div class="title-text">{{ info.titleText }}</div>
    <div v-if="info.contentText" class="desc-text" v-html="info.contentText"></div>
  </div>
</template>
<script>
export default {
  props: ['info'],
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.desc-text {
  padding: 10px;
  margin-top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px #D9D9D9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #2C2C2C;
  line-height: 20px;
}
.title-text {
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
}
.top-title-content-text-about {
  padding: 10px;
  margin-top: 10px;
  background-color: #32788A;
  border-radius: 4px;
}
</style>